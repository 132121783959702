'use client'

import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { FC } from 'react'
import { DefaultSeo } from 'next-seo'

const DefaultSEO: FC = () => {
  const {
    data: {
      attributes: {
        seo_title: title,
        meta_description: metaDescription,
        default_locale: locale,
        name,
        url,
        meta_keywords: metaKeywords,
      },
    },
  } = useStoreDataContext()

  const seoConfig = {
    title,
    titleTemplate: `%s - ${name}`,
    description: metaDescription,
    openGraph: {
      title,
      description: metaDescription,
      type: 'website',
      locale,
      url,
      site_name: name,
    },
    additionalMetaTags: [{ property: 'keywords', content: metaKeywords }],
  }

  return <DefaultSeo {...seoConfig} />
}

export default DefaultSEO
